"use client";

import Link from "next/link";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import Image from "next/image";
const HomeHeader = ({
  flow
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const SmallNav = () => {
    return <div className="absolute px-8 pb-4 rounded-bl-lg bg-white right-0 z-[999999]" data-sentry-component="SmallNav" data-sentry-source-file="HomeHeader.jsx">
        <div className="flex justify-end">
          <CloseOutlined data-sentry-element="CloseOutlined" data-sentry-source-file="HomeHeader.jsx" />
        </div>
        <ul className={`flex flex-col items-start gap-4 mt-4 z-[9999999]`}>
          <li>
            <Link href={flow === "patient" || flow === "provider" ? "/login" : "/patient"} className={({
            isActive
          }) => [isActive ? "text-parent-500" : ""].join(" ")} data-sentry-element="Link" data-sentry-source-file="HomeHeader.jsx">
              {flow === "patient" || flow === "provider" ? "" : "Patient"}
            </Link>
          </li>
          <li>
            <Link href={flow === "provider" ? "/patient" : "/provider"} className={({
            isActive
          }) => [isActive ? "text-parent-500" : ""].join(" ")} data-sentry-element="Link" data-sentry-source-file="HomeHeader.jsx">
              {flow === "provider" ? "Patient" : "Provider"}
            </Link>
          </li>
          <li>
            <Link href="/blog" className={({
            isActive
          }) => [isActive ? "text-parent-500" : ""].join(" ")} data-sentry-element="Link" data-sentry-source-file="HomeHeader.jsx">
              Blog
            </Link>
          </li>
          <li>
            <Link href="/about" className={({
            isActive
          }) => [isActive ? "text-parent-500" : ""].join(" ")} data-sentry-element="Link" data-sentry-source-file="HomeHeader.jsx">
              About
            </Link>
          </li>
          <a href="tel:4049870305">+1 (404) 987-0305</a>
        </ul>
      </div>;
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return <div className="px-6 md:px-8 lg:px-20 py-4 w-full bg-white" data-sentry-component="HomeHeader" data-sentry-source-file="HomeHeader.jsx">
      <div className="flex justify-between items-center">
        <Link href="/" data-sentry-element="Link" data-sentry-source-file="HomeHeader.jsx">
          <Image src={"/newdarklogo.png"} alt="dinma logo" className="w-24 sm:w-32" width={100} height={100} data-sentry-element="Image" data-sentry-source-file="HomeHeader.jsx" />
        </Link>

        <button className="block md:hidden" onClick={toggleMenu}>
          {showMenu ? <SmallNav /> : <MenuOutlined />}
        </button>
        <ul className={`md:flex flex-row md:space-x-8 md:items-center hidden`}>
          <li>
            <Link href={flow === "patient" || flow === "provider" ? "/login" : "/patient"} className={({
            isActive
          }) => [isActive ? "text-parent-500" : ""].join(" ")} data-sentry-element="Link" data-sentry-source-file="HomeHeader.jsx">
              {flow === "patient" || flow === "provider" ? "" : "Patient"}
            </Link>
          </li>
          <li>
            <Link href={flow === "provider" ? "/patient" : "/provider"} className={({
            isActive
          }) => [isActive ? "text-parent-500" : ""].join(" ")} data-sentry-element="Link" data-sentry-source-file="HomeHeader.jsx">
              {flow === "provider" ? "Patient" : "Provider"}
            </Link>
          </li>
          <li>
            <Link href="/blog" className={({
            isActive
          }) => [isActive ? "text-parent-500" : ""].join(" ")} data-sentry-element="Link" data-sentry-source-file="HomeHeader.jsx">
              Blog
            </Link>
          </li>
          <li>
            <Link href="/about" className={({
            isActive
          }) => [isActive ? "text-parent-500" : ""].join(" ")} data-sentry-element="Link" data-sentry-source-file="HomeHeader.jsx">
              About
            </Link>
          </li>
          <Link className="bg-parent-300 text-parent-90 rounded-full px-3 py-1" href="tel:4049870305" data-sentry-element="Link" data-sentry-source-file="HomeHeader.jsx">
            +1 (404) 987-0305
          </Link>
        </ul>
      </div>
    </div>;
};
export default HomeHeader;