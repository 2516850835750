import Image from "next/image";
const Footer = () => {
  return <div className={`bg-[#190947] lg:h-[300px] py-6 md:py-8 px-6 md:px-12 lg:32 w-full text-white-100 flex flex-col gap-10 items-center justify-between text-white`} data-sentry-component="Footer" data-sentry-source-file="Footer.jsx">
      <div className="w-full gap-5 flex flex-col lg:flex-row justify-between items-center mt-10">
        <Image className="w-28 h-auto" src={"/DINMA.svg"} alt="company's logo in white" width={100} height={100} data-sentry-element="Image" data-sentry-source-file="Footer.jsx" />
        <div className="font-light flex flex-col lg:flex-row items-center gap-6">
          <a href="/about">About</a>
          <a href="/blog">Blog</a>
          <a href="/help-center">Contact</a>
          {/* <a href="">Partnership</a> */}
          {/* <a href="">Terms of Use</a> */}
          {/* <a href="">Privacy</a> */}
        </div>
        <div className="flex gap-x-4 md:gap-x-6">
          <a href="https://x.com/getdinma">
            <Image src={"/x.svg"} alt="x/twitter url" width={25} height={25} data-sentry-element="Image" data-sentry-source-file="Footer.jsx" />
          </a>
          <a href="https://www.instagram.com/getdinma/">
            <Image src={"/instagram-icon.svg"} alt="linkedin url" width={25} height={25} data-sentry-element="Image" data-sentry-source-file="Footer.jsx" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61561184978804">
            <Image src={"/fb.svg"} alt="facebook url" width={25} height={25} data-sentry-element="Image" data-sentry-source-file="Footer.jsx" />
          </a>
          <a href="https://www.tiktok.com/@getdinma?lang=en">
            <Image className="w-6 h-6 invert" src={"/tiktokblack.png"} alt="tiktok url" width={25} height={25} data-sentry-element="Image" data-sentry-source-file="Footer.jsx" />
          </a>
        </div>
      </div>
      <div className="text-base text-parent-90 font-light w-full text-center">
        <hr className="w-full bg-white-10" />
        <br />
        &copy; 2024 Dinma. All rights reserved.
      </div>
    </div>;
};
export default Footer;